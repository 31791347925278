export default {
  basicViewItem: [{
    prop: 'id',
    label: '',
    Object: 'value'
  }, {
    prop: 'poi_ids_text',
    label: '',
    Object: 'value'
  }, {
    prop: 'name',
    label: '',
    Object: 'value'
  }, {
    prop: 'coupon_type_text',
    label: '',
    Object: 'value'
  }, {
    prop: 'discount_text',
    label: '',
    Object: 'value'
  }, {
    prop: 'target_amount_f',
    label: '',
    Object: 'value'
  }, {
    prop: 'validity_text',
    label: '',
    Object: 'value'
  }, {
    prop: 'amount_per_user',
    label: '',
    Object: 'value'
  }, {
    prop: 'price_text',
    label: '',
    Object: 'value'
  }, {
    prop: 'stock',
    label: '',
    Object: 'value'
  }, {
    prop: 'description_1',
    label: '',
    span: 6,
    Object: 'value'
  }, {
    prop: 'description',
    label: '',
    span: 12,
    Object: 'value'
  }, {
    prop: 'usage',
    label: '',
    span: 12,
    Object: 'value'
  }, {
    prop: 'images',
    label: '',
    span: 24,
    image: true
  }]
}
